.top-navbar {
    position: 'absolute';
    top: 0;
    left: 0;
    width: 100%;
}

.custom-border-primary {
    border: solid #094780 1px;
    border-radius: 10px;
}
 
.sidebar {
    background-color: #faf3d6;
    height: 100%;
    width: 50px;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    transition: 0.5s;
    overflow-x: hidden;
    padding-top: 25px;
    white-space: nowrap;

    div[role="button"] {
        padding: 4px;
        text-decoration: none;
        font-size: 25px;
        display: flex;
        align-items: center;
    }

    div[role="button"]:hover {
        color: #094780;
        font-weight: bold;
        background-color: transparent;
        transform: scale(1.02);
        -webkit-transform: scale(1.02);
        -moz-transform: scale(1.02);
        -ms-transform: scale(1.02);
        -o-transform: scale(1.02);
    }

    div[role="button"]:activate {
        background-color: #094780;
        color: white;
    }

    div #accounts {
        color: #094780;
        font-weight: bold;
        background-color: transparent;
        padding: 4px;
        text-decoration: none;
        font-size: 25px;
        display: flex;
        align-items: center;
    }

}

.sidebar-logo {
    height: 75px;
    width: 150px;
}
  
.sidebar-icon, .sidebar-icon-text {
    vertical-align: middle;
}
  
.sidebar-icon {
    padding-bottom: 3px;
    margin-right: 10px;
}

.sidebar-btn:hover {
    color: #094780;
}

.btn-outline-primary {
    border: 1px solid #094780 !important;
}
  
#main {
    padding: 16px;
    margin-left: 50px;
    transition: margin-left 0.3s;
    -webkit-transition: margin-left 0.3s;
    -moz-transition: margin-left 0.3s;
    -ms-transition: margin-left 0.3s;
    -o-transition: margin-left 0.3s;
}
  
@media screen and (max-height: 450px) {
    .sidebar {
      padding-top: 15px;
    }
    .sidebar a {
      font-size: 18px;
    }
}
