.ag-theme-balham {
  .ag-row .ag-cell {
    display: flex;
    align-items: center;
  }
  
  .ag-row-odd {
    background-color: #e7e6e6;
  }
}

.btn-custom-primary {
  background-color: #094780;
  color: white;
}

.btn-custom-primary:hover {
  border: solid #094780 1px;
  color: #094780;
}